#door {
  height: 100%;
  width: 100%;
}

.doorContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.internalDoorBanner {
  width: 100%;
  height: 55vh;
  background: url("/public/img/IMG-20240213-WA0001.jpg") center no-repeat;
  position: relative;
  background-size: cover;
}

.externalDoorBanner {
  width: 100%;
  height: 55vh;
  background: url("/public/img/1713112315681.jpg") center no-repeat;
  position: relative;
  background-size: cover;
}

.doorTitle {
  position: absolute;
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
}

.doorTitle span {
  font-size: 3vw;
  font-weight: 400;
  letter-spacing: 50px;
  text-shadow: 0 3px 5px black;
}

#doors {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.doorsContainer {
  width: 80%;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 1024px) {
  #door {
    margin-bottom: 20px;
  }

  .doorTitle span {
    font-size: 4vw;
    letter-spacing: 3.5vw;
  }

  .doorsContainer {
    flex-direction: column;
    margin-top: 20%;
    margin-bottom: 20%;
  }
}
