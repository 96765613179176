.cardContainer {
  width: 100%;
  height: max-content;
}

.cardLeftContainer {
  width: 50%;
  height: max-content;
  display: flex;
  flex-direction: column;
  border-right: solid 1px;
  box-shadow: 10px 0px 9px -11px black;
}

.cardRightContainer {
  width: 50%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  border-left: solid 1px;
  box-shadow: -5px 1px 5px -5px black;
}

.cardLeftTopImg {
  width: 90%;
}

.cardLeftImg {
  width: 90%;
  margin-top: 10%;
}

.cardRightImg {
  width: 90%;
  margin-top: 10%;
}

.cardLineTop {
  content: "";
  width: 100%;
  height: 1px;
  position: relative;
  background-color: black;
  box-shadow: 0 3px 5px black;
}

.cardLine {
  content: "";
  width: 100%;
  height: 1px;
  position: relative;
  margin-top: 10%;
  background-color: black;
  box-shadow: 0 3px 5px black;
}

.cardContentTop {
  margin-top: 10%;
}

.cardContentTop span {
  font-weight: bolder;
}

.cardContent {
  margin-top: 10%;
}

.cardContent span {
  font-size: 1.5vw;
  font-weight: bolder;
}

.cardContent p {
  font-size: 0.85vw;
}

@media (max-width: 1024px) {
  .cardLeftContainer {
    width: 100%;
    border: 0;
    box-shadow: none;
  }

  .cardLeftTopImg {
    width: 100%;
  }

  .cardLine {
    width: 100%;
  }

  .cardLeftImg {
    width: 100%;
  }

  .cardRightContainer {
    width: 100%;
    border: 0;
    box-shadow: none;
  }

  .cardRightImg {
    width: 100%;
  }

  .cardLineTop {
    display: none;
  }

  .cardContent span {
    font-size: 4vw;
  }

  .cardContent p {
    font-size: 2vw;
  }
}
