#homeCarousel {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
}

.homeCarouselContainer {
  width: 100%;
  height: max-content;
}

.homeCarousel {
  width: 100%;
  height: max-content;
  position: relative;
  display: flex;
}

.homeCarouselItem {
  z-index: 0 !important;
  transition: all 5s ease-out;
}

.homeCarouselDoorImg {
  width: 50%;
  height: 70vh;
  object-fit: cover;
  position: relative;
}

.homeCarouselItemImg {
  width: 100%;
  height: 70vh;
  object-fit: cover;
  position: relative;
}

.homeCarouselWriteup {
  width: 450px;
  height: 150px;
  position: absolute;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  top: 10%;
  right: 40%;
  border-radius: 10px;
  text-align: right;
}

.homeCarouselWriteupTitle {
  width: max-content;
  font-size: 40px;
  font-weight: 500;
  position: absolute;
  right: 7.5%;
}

@media (max-width: 1024px) {
  #homeCarousel {
    margin-top: 0;
    margin-bottom: 0;
  }

  .homeCarouselContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .carouselContainer {
    flex-direction: column;
  }

  .homeCarouselWriteup {
    width: 100%;
    height: 50vh;
    text-align: center;
    font-size: 30px;
  }

  .homeCarousel {
    width: 100%;
  }

  .homeCarouselItemImg {
    width: 100%;
    height: 90vw;
  }

  .homeCarouselDoorImg {
    width: 50%;
    height: 90vw;
    object-fit: cover;
    position: relative;
  }

  .homeCarouselWriteup {
    width: 200px;
    height: 100px;
  }

  .homeCarouselWriteupTitle {
    font-size: 20px;
  }
}
