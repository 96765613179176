#projects {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectsContainer {
  width: 90%;
  height: 100%;
}

.projectsBanner {
  width: 100%;
  height: 30vh;
  display: flex;
  background-color: lightgray;
  justify-content: center;
  align-items: center;
}

.projectsTitle {
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 50px;
  color: white;
  text-shadow: 0 3px 5px black;
}

.projectsContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.projectsContentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 5%;
}

.projectsWriteupContainer {
  width: 100%;
  height: 100%;
  display: flex;
  margin-bottom: 1%;
}

.projectsWriteupLeft {
  width: 50%;
  height: 100%;
}

.projectsWriteupRight {
  width: 50%;
  height: 500px;
}

.projectsName {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 900;
  font-size: 50px;
  text-align: center;
  margin-top: 10%;
}

.projectsLocation {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
}

.projectsDescription {
  width: 100%;
  display: flex;
  justify-content: center;
}

.projectsCarousel {
  width: 100%;
  height: 100%;
}

.projectsImg {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.projectsCarouselImg {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .projectsBanner {
    margin-top: 15%;
    height: 12.5vh;
  }

  .projectsTitle {
    font-size: 25px;
    letter-spacing: 15px;
  }

  .projectsWriteupContainer {
    flex-direction: column;
    margin-top: none;
    margin-bottom: none;
  }

  .projectsWriteupLeft {
    width: 100%;
    margin-bottom: 10%;
  }

  .projectsWriteupRight {
    width: 100%;
  }

  .projectsName {
    font-size: 37px;
  }

  .projectsLocation {
    font-size: 20px;
  }
}
