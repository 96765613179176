.companyMainInfoContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.companyMainInfo {
  margin-top: 150px;
  width: 75%;
  margin-bottom: 150px;
}

.companyMainInfoTitle {
  font-size: 2.5rem;
  color: brown;
  font-weight: 100;
  font-family: "Canela Text Trial", sans-serif;
}

@media (max-width: 1024px) {
  .companyMainInfo {
    margin-top: 150px;
    margin-bottom: 150px;
  }

  .companyMainInfoTitle {
    font-size: 2rem;
  }
}
