#homeCard {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.homeCardContainer {
  width: 375px;
  height: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeCard1 {
  width: 300px;
  height: 300px;
  border: 0;
  display: flex;
  align-items: center;
  background-image: url("/public/img/IMG-20240213-WA0004.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}

.homeCard1:hover {
  width: 325px;
  height: 325px;
  transition: all 0.5s ease-in-out;
}

.homeCard1::before {
  content: "";
  width: 300px;
  height: 300px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}

.homeCard1:hover:before {
  width: 350px;
  height: 350px;
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}

.homeCard2 {
  width: 300px;
  height: 300px;
  border: 0;
  display: flex;
  align-items: center;
  background-image: url("/public/img/IMG-20240403-WA0012.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}
.homeCard2:hover {
  width: 325px;
  height: 325px;
  transition: all 0.5s ease-in-out;
}

.homeCard2::before {
  content: "";
  width: 300px;
  height: 300px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 1;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}

.homeCard2:hover:before {
  width: 350px;
  height: 350px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.homeCard3 {
  width: 300px;
  height: 300px;
  border: 0;
  display: flex;
  align-items: center;
  background-image: url("/public/img/1712132791857.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}

.homeCard3:hover {
  width: 325px;
  height: 325px;
  transition: all 0.5s ease-in-out;
}

.homeCard3::before {
  content: "";
  width: 300px;
  height: 300px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}

.homeCard3:hover:before {
  width: 350px;
  height: 350px;
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}

.homeCard4 {
  width: 300px;
  height: 300px;
  border: 0;
  display: flex;
  align-items: center;
  background-image: url("/public/img/ironmongeryHome.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}

.homeCard4:hover {
  width: 325px;
  height: 325px;
  transition: all 0.5s ease-in-out;
}

.homeCard4::before {
  content: "";
  width: 300px;
  height: 300px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}

.homeCard4:hover:before {
  width: 350px;
  height: 350px;
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}

.homeCardWriteUp {
  width: 250px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  position: absolute;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.homeCardWriteUp span {
  font-weight: 400;
}

@media (max-width: 1024px) {
  #homeCard {
    flex-direction: column;
    justify-content: unset;
    margin-top: 100px;
    gap: 10px;
  }
}
