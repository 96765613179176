#ironmongery {
  height: 100%;
  width: 100%;
}

.ironmongeryContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.handleBanner {
  width: 100%;
  height: 55vh;
  background: url("/public/img/ironmongeryBanner.jpg") center no-repeat;
  position: relative;
  background-size: cover;
}

.handleTitle {
  position: absolute;
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.handleTitle span {
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 50px;
  text-shadow: 0 3px 5px black;
}

#handles {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.handlesContainer {
  width: 80%;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.locknkeyBanner {
  width: 100%;
  height: 55vh;
  background: url("/public/img/locksnkeyBanner.jpg") center no-repeat;
  position: relative;
  background-size: cover;
}

.locknkeyTitle {
  position: absolute;
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.locknkeyTitle span {
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 50px;
  text-shadow: 0 3px 5px black;
}

#locknkeys {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.locknkeysContainer {
  width: 80%;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.hingeBanner {
  width: 100%;
  height: 55vh;
  background: url("/public/img/hingesBanner.png") center no-repeat;
  position: relative;
  background-size: cover;
}

.hingeTitle {
  position: absolute;
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.hingeTitle span {
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 50px;
  text-shadow: 0 3px 5px black;
}

#hinges {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.hingesContainer {
  width: 80%;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 1024px) {
  #ironmongery {
    margin-bottom: 20px;
  }

  .handleTitle span {
    font-size: 40px;
    letter-spacing: 20px;
  }

  .locknkeyTitle span {
    font-size: 25px;
    letter-spacing: 20px;
  }

  .hingeTitle span {
    font-size: 40px;
    letter-spacing: 20px;
  }

  .otherAccTitle span {
    font-size: 30px;
    letter-spacing: 7px;
  }

  .handlesContainer {
    flex-direction: column;
    margin-top: 20%;
    margin-bottom: 20%;
  }

  .hingesContainer {
    flex-direction: column;
    margin-top: 20%;
    margin-bottom: 20%;
  }

  .locknkeysContainer {
    flex-direction: column;
    margin-top: 20%;
    margin-bottom: 20%;
  }

  .otherAccsContainer {
    flex-direction: column;
    margin-top: 20%;
    margin-bottom: 20%;
  }
}
