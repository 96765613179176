#wardrobe {
  height: 100%;
  width: 100%;
}

.wardrobeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wardrobeBanner {
  width: 100%;
  height: 55vh;
  background: url("/public/img/1712132877592.jpg") center no-repeat;
  position: relative;
  background-size: cover;
}

.wardrobeBanner::before {
  content: "";
  width: 100%;
  min-height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
}

.wardrobeTitle {
  position: absolute;
  width: 100%;
  height: 55vh;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
}

.wardrobeTitle span {
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 50px;
  text-shadow: 0 3px 5px black;
}

#wardrobes {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.wardrobesContainer {
  width: 80%;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 1024px) {
  #wardrobe {
    margin-bottom: 20px;
  }

  .wardrobeTitle span {
    font-size: 40px;
    letter-spacing: 20px;
  }

  .wardrobesContainer {
    flex-direction: column;
    margin-top: 20%;
    margin-bottom: 20%;
  }
}
