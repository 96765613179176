@import url("https://fonts.cdnfonts.com/css/canela-text-trial");

#aboutus {
  width: 100%;
  height: max-content;
  position: relative;
}

.aboutusContainer {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutusBanner {
  width: 100%;
  height: 30vh;
  display: flex;
  background-color: lightgray;
  justify-content: center;
  align-items: center;
}

.aboutusTitle {
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 50px;
  color: white;
  text-shadow: 0 3px 5px black;
}

.aboutusImgContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 10px;
}

.aboutusImg1 {
  width: 400px;
  height: 400px;
}

.aboutusImg2 {
  height: 450px;
  width: 350px;
  object-fit: cover;
}

.aboutusImg3 {
  width: 357px;
  height: 300px;
  object-fit: cover;
}

.aboutusProjectContainer {
  display: flex;
  flex-direction: row;
  gap: 200px;
  margin-top: 10%;
  margin-bottom: 10%;
}

.yearsInInd h1 {
  color: brown;
  font-size: 70px;
  font-family: "Canela Text Trial", sans-serif;
}

.finishedProj h1 {
  color: brown;
  font-size: 70px;
  font-family: "Canela Text Trial", sans-serif;
}

.num {
  color: brown;
  font-size: 70px;
  font-family: "Canela Text Trial", sans-serif;
}

.aboutusProjectContent {
  font-size: 20px;
}

.meetTheTeamTitle span {
  font-size: 20px;
  font-weight: bolder;
}

.meetTheTeamContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.meetTheTeamCard {
  width: 33.33%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 10px;
  gap: 20px;
}

.meetTheTeamImg {
  width: 400px;
  height: 450px;
}

.deliveryProcessContainer {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10%;
}

.deliveryProcessTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10%;
}

.titleDash {
  content: "";
  height: 1px;
  width: 50px;
  background-color: black;
  box-shadow: 0 3px 5px black;
}

.deliveryProcessTitle span {
  font-size: 20px;
  font-weight: bolder;
}

.deliveryProcessContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.deliveryProcess {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 110px;
}

.deliveryProcessCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.process {
  font-size: 100px;
  margin-bottom: 30px;
  color: brown;
}

.arrow {
  font-size: 40px;
}

.deliveryProcessCard span {
  font-size: 20px;
  font-family: "Canela Text Trial", sans-serif;
}

@media (max-width: 1024px) {
  .aboutusBanner {
    margin-top: 15%;
    height: 12.5vh;
  }

  .aboutusTitle {
    font-size: 25px;
    letter-spacing: 15px;
  }

  .aboutusImgContainer {
    flex-direction: column;
  }

  .aboutusProjectContainer {
    flex-direction: column;
    gap: 50px;
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .meetTheTeamContent {
    gap: 100px;
    margin-top: 100px;
  }

  .meetTheTeamCard {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 100px;
  }

  .deliveryProcess {
    gap: 20px;
  }

  .process {
    font-size: 30px;
  }

  .arrow {
    font-size: 15px;
  }

  .deliveryProcessCard span {
    font-size: 10px;
  }
}
