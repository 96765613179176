#nav {
  background-color: rgba(211, 211, 211, 0.2);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  max-width: 100%;
  height: 100px;
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  right: 0;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  z-index: 99999;
  transition: all 1s ease-in-out;
}

#nav.sticky {
  background-color: rgba(211, 211, 211, 0.7);
  box-shadow: 0 2px 3px black;
  position: fixed;
  height: 100px;
}

.navContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.pointedgeLogoWrap {
  height: 100%;
  margin-left: 5%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  width: max-content;
  height: max-content;
}

.pointedgeLogo {
  width: 225px;
  height: 30px;
}

.navMenu {
  width: max-content;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  gap: 50px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.dropdownNavLink {
  font-size: 1vw;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  color: white;
  text-decoration: underline 0.15em rgba(140, 0, 0, 0);
  transition: text-decoration-color 1s;
  cursor: pointer;
}

.dropdownNavLink:hover {
  text-decoration-color: white;
}

.dropdownNavLink:hover .navLink {
  display: flex;
}

.navMenuLink {
  font-size: 1vw;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  color: white;
  text-decoration: underline 0.15em rgba(0, 0, 0, 0);
  transition: text-decoration-color 1s;
}

.navMenuLink:hover {
  text-decoration-color: white;
}

.navLinkContainer {
  width: 200px;
  display: flex;
  position: absolute;
  background-color: lightgray;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px 0 black;
}

.navLinkWrap {
  width: max-content;
}

.navLink {
  width: max-content;
  font-size: 1vw;
  font-weight: 500;
  letter-spacing: 0em;
  text-decoration: none;
  color: white;
  display: none;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: underline 0.15em rgba(0, 0, 0, 0);
  transition: text-decoration-color 1s;
}

.navLink:hover {
  text-decoration-color: white;
}

.contactusBtnContainer {
  position: absolute;
  display: flex;
  align-items: center;
  right: 5%;
}

.contactusBtn {
  width: 230px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
  font-weight: 500;
  border: solid;
  border-radius: 50px;
  text-decoration: none;
}

.contactusBtn:hover {
  border: hidden;
}

#mobileNavBar {
  display: none;
}

@media (max-width: 1024px) {
  #nav {
    display: none;
  }

  #mobileNavBar {
    position: fixed;
    display: flex;
    width: 100%;
    height: 75px;
    align-items: center;
    background-color: lightgray;
    z-index: 1;
    box-shadow: 0 1px 5px black;
  }

  .mobileNavLogoWrap {
    position: relative;
    display: flex;
    width: max-content;
    left: 2rem;
  }

  .mobileNavLogo {
    width: 100px;
  }

  .mobileNavToggle {
    position: absolute;
    right: 2rem;
    font-size: 40px;
    border: 0;
    background-color: transparent;
    z-index: 99999;
  }

  .mobileNavToggle:hover {
    color: white;
  }

  .mobileNavLinksContainer {
    position: absolute;
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    transition: 3s;
    transform: translateY(-600px);
    list-style-type: none;
    gap: 1.5rem;
  }

  .mobileNavLinksContainer.responsive_mobilenav {
    transform: none;
  }

  .mobileNavLinksContent {
    width: 90%;
    height: 525px;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    background-color: lightgray;
    box-shadow: 0 2px 3px black;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .mobileLogoNavLinkWrap {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: max-content;
  }

  .mobileLogoNavLink {
    margin-top: 30px;
    width: 40%;
    margin-bottom: 20px;
  }

  .topMobileNavLinks {
    width: 100%;
    display: flex;
    justify-content: center;
    color: black;
    text-decoration: none;
    font-weight: bolder;
    font-size: 20px;
    margin-top: 50px;
  }

  .topMobileNavLinks:hover {
    color: white;
  }

  .mobileNavLinksContentContainer {
    height: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .mobileNavContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .mobileNavLinks {
    color: black;
    text-decoration: none;
    font-weight: bolder;
    font-size: 20px;
  }

  .mobileNavLinks:hover {
    color: white;
  }

  .mobileContactusBtnContainer {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .mobileContactusBtn {
    width: 230px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: rgba(255, 255, 255, 0.7);
    font-weight: bold;
    font-weight: 500;
    border: solid;
    border-radius: 50px;
    text-decoration: none;
  }

  .mobileContactusBtn:hover {
    border: none;
  }
}

@media (max-height: 600px) {
  .mobileLogoNavLink {
    width: 20%;
  }

  .mobileNavLinksContent {
    height: 350px;
    width: 95%;
  }

  .mobileNavContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}
