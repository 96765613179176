#homePatners {
  width: 100%;
  height: 100%;
}

.homePatnersContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow: hidden;
}

.homePatnersTitle {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 50px;
}

.homePatnersTitle span {
  font-weight: 700;
  font-size: 20px;
}

.homePatnersTitleDash {
  content: "";
  height: 1px;
  width: 50px;
  background-color: black;
  box-shadow: 0 3px 5px black;
  margin-top: 15px;
}

.homePatnersImg {
  height: 50px;
  margin-right: 30px;
}

.homePatnersImgContainer {
  width: 100%;
  height: 100%;
}
