#footer {
  width: 100%;
  height: max-content;
  float: right;
  background-color: rgba(211, 211, 211, 0.2);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}

.footerContainer {
  width: 75%;
  height: max-content;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 50px;
}

.footerMobileContainer {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.leftFooter {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.footerAddressTitle {
  margin-bottom: 73px;
}

.footerEmailTitle {
  margin-bottom: 20px;
}

.socialsTitle {
  font-size: 16px;
}

.footerAddress {
  width: 100%;
  line-height: 23px;
  margin-bottom: 27px;
}

.footerEmail {
  width: 100%;
  margin-bottom: 19px;
}

.footerTel {
  width: 100%;
  margin-bottom: 20px;
}

.centerFooter {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.footerLinks:hover {
  color: beige;
}

.footerContactUs:hover {
  color: beige;
}

.rightFooter {
  width: 35%;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: 17px;
}

.footerSocials {
  display: flex;
  flex-direction: row;
  font-size: 25px;
  color: black;
  align-items: center;
  gap: 50px;
}

.footerEmailTitle {
  font-size: 16px;
}

.insta {
  color: black;
}

.insta:hover {
  color: lightgray;
}

.facebook {
  color: black;
}

.facebook:hover {
  color: lightgray;
}

.linkedin {
  color: black;
}

.linkedin:hover {
  color: lightgray;
}

.newsletterContainer {
  padding: 0;
}

.input {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

#emailSub {
  width: 275px;
  height: 40px;
  border-radius: 10px;
}

#subSubmit {
  width: 150px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 0;
  margin: 0;
}

#subSubmit:hover {
  border: hidden;
}

#subSubmitTxt {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.copyright {
  width: 100%;
}

@media (max-width: 1024px) {
  .footerContainer {
    flex-direction: column;
  }

  .footerMobileContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .leftFooter {
    width: 30%;
  }

  .footerAddressTitle {
    margin-bottom: 73px;
  }

  .centerFooter {
    width: 70%;
  }

  .rightFooter {
    width: 100%;
  }
}
