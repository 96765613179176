#contactus {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: url(/public/img/1712132877587.jpg) top center;
  background-size: cover;
}

.contactusContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.mapContainer {
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
}

.map {
  width: 75%;
  height: 70vh;
  border-radius: 50px;
  box-shadow: 0 10px 50px #dbdbdb;
  margin-top: 225px;
  margin-bottom: 75px;
}

.formContainer {
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
}

.formWrap {
  width: 75%;
  height: 100%;
  margin-top: 225px;
  margin-bottom: 75px;
  display: flex;
  justify-content: center;
  align-content: center;
}

form {
  width: 100%;
  height: 100%;
}

#firstName {
  width: 45%;
  height: 75px;
  padding: 2%;
  border-radius: 50px;
  border: 1px solid;
  border-color: #d6d5d5;
  box-shadow: 0 10px 50px black;
}

#lastName {
  width: 45%;
  height: 75px;
  padding: 2%;
  border-radius: 50px;
  border: 1px solid;
  border-color: #d6d5d5;
  float: right;
  box-shadow: 0 10px 50px black;
}

#email {
  width: 55%;
  height: 75px;
  padding: 2%;
  border-radius: 50px;
  border: 1px solid;
  border-color: #d6d5d5;
  box-shadow: 0 10px 50px black;
  margin-top: 50px;
}

#phoneNumber {
  width: 35%;
  height: 75px;
  padding: 2%;
  border-radius: 50px;
  border: 1px solid;
  border-color: #d6d5d5;
  box-shadow: 0 10px 50px black;
  margin-top: 50px;
  float: right;
}

#message {
  width: 100%;
  padding: 2%;
  border-radius: 25px;
  border: 1px solid;
  border-color: #d6d5d5;
  box-shadow: 0 10px 50px black;
  margin-top: 50px;
}

::placeholder {
  font-weight: 500;
  letter-spacing: 0em;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
}

#submit {
  width: 200px;
  height: 75px;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 10px 50px black;
  color: black;
  border: solid;
  position: relative;
  margin-top: 50px;
}

#submit:hover {
  border: hidden;
}

#submitTxt {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0em;
  margin: 0;
}

@media (max-width: 1024px) {
  .contactusContainer {
    flex-direction: column;
  }
  .mapContainer {
    width: 100%;
  }

  .map {
    height: 100vw;
    margin-top: 150px;
  }
  .formContainer {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  #firstName {
    width: 100%;
    margin-bottom: 50px;
  }

  #lastName {
    width: 100%;
    float: none;
  }

  #email {
    width: 100%;
  }

  #phoneNumber {
    width: 100%;
    float: none;
  }
}
