#kitchen {
  height: 100%;
  width: 100%;
}

.kitchenContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kitchenBanner {
  width: 100%;
  height: 55vh;
  background: url("/public/img/1712132877575.jpg") center no-repeat;
  position: relative;
  background-size: cover;
}

.kitchenBanner::before {
  content: "";
  width: 100%;
  min-height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
}

.kitchenTitle {
  position: absolute;
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
}

.kitchenTitle span {
  font-size: 3vw;
  font-weight: 400;
  letter-spacing: 50px;
  text-shadow: 0 3px 5px black;
}

#kitchens {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.kitchensContainer {
  width: 80%;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 1024px) {
  #kitchen {
    margin-bottom: 20px;
  }

  .kitchenTitle span {
    font-size: 4vw;
    letter-spacing: 3.5vw;
  }

  .kitchensContainer {
    flex-direction: column;
    margin-top: 20%;
    margin-bottom: 20%;
  }
}
