@import url("https://fonts.cdnfonts.com/css/canela-text-trial");

#home {
  width: 100%;
  height: 100vh;
  background-size: cover;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-attachment: fixed;
}

#home::before {
  content: "";
  width: 100%;
  min-height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
}

.homeBanner {
  width: 33.33%;
  height: 100%;
  display: flex;
  object-fit: cover;
}

.homeBannerCarousel {
  height: 100%;
  display: none;
}

.homeWriteupTitle {
  width: 850px;
  position: absolute;
  text-align: right;
  right: 5%;
  line-height: 2.7rem;
}

.homeWriteupTitle span {
  height: 20px;
  font-size: 3rem !important;
  color: white;
  font-weight: 100;
  font-family: "Canela Text Trial", sans-serif;
}

.homeWriteupContainer {
  margin-top: 2vw;
  margin-left: 5vw;
  margin-right: 3vw;
  margin-bottom: 2vw;
}

.homeWriteupContainer span {
  font-size: 1.2vw;
  font-weight: 400;
  color: black;
}

.homeMoreContainer {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: right;
}

.homeMoreBtn {
  width: 170px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
  font-weight: 500;
  border: solid;
  border-radius: 50px;
  text-decoration: none;
  margin-top: 10px;
}

.homeMoreBtn:hover {
  border: hidden;
}

@media (max-width: 1024px) {
  .videoBg {
    width: 1192px;
  }

  .homeBanner {
    display: none;
  }

  .homeBannerCarousel {
    height: 100%;
    width: 100%;
    display: flex;
    z-index: 0;
  }

  .homeBannerCarouselItem {
    display: flex !important;
    justify-content: center;
    object-fit: cover;
    object-position: center;
  }

  .homeWriteupTitle {
    width: 50%;
  }

  .homeWriteupTitle span {
    font-size: 32px !important;
  }

  .homeMoreBtn {
    width: 150px;
    height: 50px;
    font-size: 15px;
  }
}
